<template>
  <div class="d-flex align-center mt-3">
    <el-button class="message" @click="sendMessage()" type="info"
      >與老師聯繫</el-button
    >
    <div class="socialMedia ms-2">
      <a
        :href="teacherData.socialMedia.fb"
        v-if="teacherData.socialMedia.fb != ''"
        ><i class="fa-brands fa-FaceBook"></i
      ></a>
      <!-- v-if="teacherData.socialMedia.ig != ''" -->
      <a :href="teacherData.socialMedia.ig"
        ><i class="fa-brands fa-instagram"></i
      ></a>
      <!-- v-if="teacherData.socialMedia.twitter != ''" -->
      <a :href="teacherData.socialMedia.twitter"
        ><i class="fa-brands fa-twitter"></i
      ></a>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    sendMessage() {
      this.$store.state.isTalk = true;
      this.$store.commit("OpenMessageWin");
      this.$store.commit(
        "CreateMessageGroup",
        this.teacherData.teacehrMemberId
      );
    },
  },
  props: {
    teacherData: Object,
  },
};
</script>
<style lang="scss" scoped >
.fa-brands {
  font-size: 30px !important;
  margin-right: 1rem;
  &.fa-FaceBook {
    color: #3b5998;
  }
  &.fa-instagram {
    color: #e1306c;
  }
  &.fa-twitter {
    color: #1da1f2;
  }
}
</style>