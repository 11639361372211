<template>
  <router-link :to="`/ChargeMeTribe/Class/${item.id}`" class="link">
    <el-card class="box-card" shadow="hover">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mt-2 w-100">
          <h3 class="className">{{ item.name }}</h3>
          <p :class="item.degree" class="degreeTip" v-if="item.degree != ''">
            <span>{{ getDegree(item.degree) }}</span>
            <span>/</span>
            <span v-for="(t, i) in item.teachingTypes" :key="i">
              {{ t }}
            </span>
          </p>
          <strong class="fw-bold classPrice">
            <span>NT$</span>
            {{ item.price }}/60分鐘
          </strong>
        </div>
        <i class="fas fa-angle-right"></i>
      </div>
    </el-card>
  </router-link>
</template>


<script >
export default {
  data() {
    return {};
  },
  props: {
    item: Object,
  },
};
</script>
<style lang="scss"  scoped>
.link {
  text-decoration: none;
}
.box-card {
  position: relative;
}
.className {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ff9900 !important;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.degreeTip {
  position: absolute;
  left: 0;
  top: 0rem;
  padding: 0.2rem 0.6rem;
  color: white;
  background-color: $main_color;
  // border-radius: 0 8px 8px 0;
  font-weight: 600;
  // border-right:2px white solid ;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}
.classPrice {
  font-size: 14px;
  font-weight: bold;
}
.Beginner {
  background-color: #129600;
}
.Elementary {
  background-color: #d8e2ff;
}
.Intermediate {
  background-color: #ffd000;
}
.Advance {
  background-color: #ffb593;
}
.Master {
  background-color: #ff897a;
}

@media screen and (min-width: 768px) {
  .box-card {
    margin-bottom: 0.5rem;
  }
}
</style>
