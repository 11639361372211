<template>
  <div class="teacher_introduce" id="teacher_introduce">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/ChargeMeTribe' }">首頁</el-breadcrumb-item>
      <div v-if="prevRoute != null && prevRoute.path != '/'">
        <el-breadcrumb-item :to="{ path: '/ChargeMeTribe/Search' }">搜尋</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/ChargeMeTribe/Class/${prevRoute.params.id}` }"
          >課程資訊</el-breadcrumb-item
        >
        <el-breadcrumb-item>老師介紹</el-breadcrumb-item>
      </div>
      <el-breadcrumb-item v-else>老師介紹</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="teacher_info">
      <div class="teacher_class" v-if="!$store.getters.mobileSize">
        <h3 class="h5">精選課程</h3>
        <CourseCard
          :item="item"
          v-for="item in teacherData.courses.slice(0, 5)"
          :key="item"
        />
        <a href="" class="moreClass">顯示更多課程</a>
        <hr />
        <TeacherCommunity :teacherData="teacherData" />
      </div>
      <div class="top" :class="{ 'd-inline-block': $store.getters.mobileSize }">
        <div
          class="image mx-3"
          :class="{ 'text-center': $store.getters.mobileSize }"
        >
          <el-avatar
            :size="80"
            :src="teacherData.avatar"
            v-if="teacherData.avatar != ''"
          />
        </div>

        <div class="intro">
          <span class="name">{{ teacherData.name }}</span>
          <div class="brief">{{ teacherData.brief }}</div>
          <div class="mix">
            <span class="teachSeniority"
              >資歷：{{ teacherData.teachSeniority }}</span
            >
          </div>
          <TeacherCommunity :teacherData="teacherData" />
        </div>
      </div>
      <hr />
      <div class="video">
        <h3 class="h5">影片介紹</h3>
        <iframe
          :src="teacherData.video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="video"
        ></iframe>
      </div>
      <hr />
      <div class="profile">
        <h3 class="h5">老師資料</h3>
        <div>
          <div class="teachingInstrument">
            <h5>教學樂器</h5>
            <div class="my-2">
              <el-tag
                v-for="(data, index) in teacherData.teachingInstrument"
                :key="index"
                size="large"
                color="#d6d4d4"
                class="me-2 text-white mb-1"
                round
                >{{ data }}</el-tag
              >
            </div>
          </div>
          <div class="teachingType">
            <h5>教學類型</h5>
            <div class="my-2">
              <el-tag
                v-for="(data, index) in teacherData.teachingType"
                :key="index"
                size="large"
                color="#d6d4d4"
                class="me-2 text-white mb-1"
                round
                >{{ data }}</el-tag
              >
            </div>
          </div>
          <div class="teachingArea">
            <h5>教學地區</h5>
            <div class="my-2">
              <el-tag
                v-for="(data, index) in teacherData.teachingArea"
                :key="index"
                size="large"
                color="#d6d4d4"
                class="me-2 text-white mb-1"
                round
                >{{ data }}</el-tag
              >
            </div>
          </div>
          <div class="Feature">
            <h5>其他特色</h5>
            <div class="my-2">
              <el-tag
                v-if="teacherData.isHasGroupTeachExperience"
                size="large"
                color="#d6d4d4"
                class="me-2 text-white mb-1"
                round
                >有團體課程經驗</el-tag
              >
              <el-tag
                v-if="teacherData.canAdjustTeachWay"
                size="large"
                color="#d6d4d4"
                class="me-2 text-white mb-1"
                round
                >可調整教學方式</el-tag
              >
            </div>
          </div>
        </div>
      </div>
      <hr v-if="$store.getters.mobileSize" />
      <div v-if="$store.getters.mobileSize">
        <h3 class="h5">精選課程</h3>
        <el-carousel :interval="5000" type="card" height="100px">
          <el-carousel-item
            height="180px"
            v-for="(item, index) in teacherData.courses.slice(0, 5)"
            :key="index"
          >
            <CourseCard :item="item" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="resume">
        <el-collapse v-model="resumeActive" @change="handleChange">
          <el-collapse-item title="自我介紹" name="1">
            <div v-html="teacherData.introduction"></div>
          </el-collapse-item>
          <el-collapse-item title="工作經驗" name="2">
            <ul>
              <li
                v-for="(data, index) in teacherData.resume.experience"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="教育背景" name="3">
            <ul>
              <li
                v-for="(data, index) in teacherData.resume.education"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="證照證書" name="4">
            <ul>
              <li
                v-for="(data, index) in teacherData.resume.certification"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="預約方式" name="5">
            <ul>
              <li
                v-for="(data, index) in teacherData.commonProblem.appointment"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="費用說明" name="6">
            <ul>
              <li
                v-for="(data, index) in teacherData.commonProblem
                  .chargeDescription"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="上課說明" name="7">
            <ul>
              <li
                v-for="(data, index) in teacherData.commonProblem
                  .classDescription"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="退課須知" name="8">
            <ul>
              <li
                v-for="(data, index) in teacherData.commonProblem
                  .dropDescription"
                :key="index"
              >
                {{ data }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="個人資訊" name="9">
            <div class="basicInfo">
              <div class="info">
                <div class="mb-3">Email：{{ teacherData.email || "無" }}</div>
                <div class="mb-3">
                  Line ID：{{ teacherData.socialMedia.line || "無" }}
                </div>
                <div class="mb-3">
                  行動電話：{{ teacherData.cellPhone || "無" }}
                </div>
                <div class="mb-3">
                  市內電話：{{ teacherData.phone || "無" }}
                </div>
                <div class="mb-3">
                  銀行代碼：{{ teacherData.bankCode || "請與老師聯繫" }}
                </div>
                <div class="mb-3">
                  銀行帳戶：{{ teacherData.bankAccount || "請與老師聯繫" }}
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="其他課程" name="10">
            <div class="otherClass">
              <VueCal
                :teacherId="teacherData.teacherId"
                :teacherMemberId="teacherData.teacehrMemberId"
              />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>


<script>
import CourseCard from "../../components/ChargeMeTribe/TeacherIntroduce/CourseCard.vue";
import TeacherCommunity from "../../components/ChargeMeTribe/TeacherIntroduce/TeacherCommunity.vue";
import VueCal from "../../components/ChargeMeTribe/TeacherIntroduce/TeacherCal.vue";

export default {
  data() {
    return {
      introductionHeight: 0,
      prevRoute: {
        params: { id: "" },
      },
      Isexpand: false,
      resumeActive: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      commonProblemActive: [],
      teacherData: {
        name: "",
        avatar: "",
        brief: "",
        video: "",
        introduction: "",
        courses: [],
        teacherId: "",
        teachingType: [],
        teachingInstrument: [],
        teachingArea: [],
        teachingLanguage: [],
        teachStudentCount: 0,
        teachStudentHour: 0,
        resume: {
          experience: [],
          education: [],
          certification: [],
        },
        teachingTime: [],
        teachingRate: "",
        commonProblem: {
          appointment: [],
          chargeDescription: [],
          classDescription: [],
          dropDescription: [],
        },
        description: "",
        phone: "",
        teachSeniority: "",
        isHasGroupTeachExperience: true,
        canAdjustTeachWay: true,
        email: "",
        cellPhone: "",
        socialMedia: {
          fb: "",
          ig: "",
          twitter: "",
          line: "",
        },
      },
    };
  },
  components: {
    CourseCard,
    TeacherCommunity,
    VueCal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      Object.assign(vm.prevRoute, from);
    });
  },
  async created() {
    await this.initData();
  },

  watch: {
    introductionHeight: function () {
      console.log(this.introductionHeight);
    },
  },
  methods: {
    getTeachSeniority(data) {
      let temp = "";
      switch (data) {
        case "LessThanOne": {
          temp = "1年以下";
          break;
        }
        case "OneToThree": {
          temp = "1~3年";
          break;
        }
        case "ThreeToFive": {
          temp = "3~5年";
          break;
        }
        case "GreaterThanFive": {
          temp = "5年以上";
          break;
        }
      }
      return temp;
    },
    async initData() {
      const vm = this;
      await vm
        .fetchAPI(
          "get",
          `${process.env.VUE_APP_URL_API}/teacher/${vm.$route.params.id}/info`
        )
        .then((res) => {
          vm.teacherData = {
            teacehrMemberId: vm.$route.params.id,
            teacherId: res.data.teacherId,
            courses: vm.checkArrayNull(res.data.courses.courses),
            brief: res.data.simpleIntroduction,
            editorData: res.data.selfIntroduction,
            video: res.data.introVideoUrl.includes("https")
              ? vm.checkVideo(res.data.introVideoUrl)
              : vm.$store.state.default.video,
            experience: vm.checkArrayNull(res.data.workExperiences),
            education: vm.checkArrayNull(res.data.educationBackgrounds),
            certification: vm.checkArrayNull(res.data.licenses),
            appointment: vm.checkArrayNull(res.data.reserveMethod),
            chargeDescription: vm.checkArrayNull(res.data.payIntro),
            classDescription: vm.checkArrayNull(res.data.courseIntro),
            dropDescription: vm.checkArrayNull(res.data.refundIntro),
            name: res.data.nickName,
            avatar: vm.imageProcess(res.data.avatar),
            introduction: res.data.selfIntroduction,
            teachingType: vm.turnTeachingType(res.data.teachingType),
            teachingArea: vm.turnTeachingArea(res.data.teachingArea),
            socialMedia:
              res.data.socialMedia == ""
                ? { line: "", fb: "", ig: "", twitter: "" }
                : JSON.parse(res.data.socialMedia),
            resume: {
              experience: vm.checkArrayNull(res.data.workExperiences),
              education: vm.checkArrayNull(res.data.educationBackgrounds),
              certification: vm.checkArrayNull(res.data.licenses),
            },
            commonProblem: {
              appointment: vm.checkArrayNull(res.data.reserveMethod),
              chargeDescription: vm.checkArrayNull(res.data.payIntro),
              classDescription: vm.checkArrayNull(res.data.courseIntro),
              dropDescription: vm.checkArrayNull(res.data.refundIntro),
            },
            description: res.data.description,
            phone: res.data.phone,
            teachSeniority: vm.getTeachSeniority(res.data.teachSeniority),
            isHasGroupTeachExperience: res.data.isHasGroupTeachExperience,
            canAdjustTeachWay: res.data.canAdjustTeachWay,
            email: res.data.email,
            cellPhone: res.data.cellPhone,
            teachingInstrument: vm.turnInstrument(res.data.instrument),
            bankCode: res.data.bankCode,
            bankAccount: res.data.bankAccount,
          };

          // vm.teacherData.courses.teachingTypes.map((x) =>
          //   vm.turnTeachingType(x))
          vm.teacherData.courses.forEach((i) => {
            i.teachingTypes.forEach((x) => (x = vm.turnTeachingType(x)));
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    turnInstrument(arr) {
      let temp = [];
      arr.forEach((x) => {
        temp.push(
          this.$store.state.common.simpleInstrumentOptions.find(
            (y) => y.value == x
          ).label
        );
      });
      return temp;
    },
    turnTeachingType(str) {
      let arr = str.split(",");
      let temp = [];
      arr.forEach((x) => {
        if (x == "Online") temp.push("遠端");
        if (x == "Onsite") temp.push("實體");
      });
      return temp;
    },
    turnTeachingArea(str) {
      let arr = str.split(",");
      let temp = [];
      arr.forEach((x) => {
        let obj = this.$store.state.common.simpleAreaOptions.find(
          (y) => y.value == x
        );
        temp.push(`${obj.county}/${obj.label}`);
      });
      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item .medium {
  color: #475669;
  font-size: 14px;
  // opacity: 0.9;
  margin: 0;
  position: relative;
}

.teacher_introduce {
  position: relative;
  margin: auto;
  text-align: start;
  padding: 1rem;
  width: 100%;
  list-style-type: circle !important;
  & > div {
    margin: 2rem 0;
  }

  .teacher_info {
    width: 100%;

    .teacher_class {
      width: 40%;
      left: calc(50% + 160px);
      width: 340px;
      position: absolute;
      transform: translateY(-40px);
      padding: 0 1rem;
      h3 {
        font-size: 1.5rem;
        // font-weight: 600;
        margin-bottom: 1rem;
      }
      .box-card {
        margin-bottom: 0.5rem;
      }

      .moreClass {
        display: block;
        text-align: center;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.55);
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .introduction {
      width: 100%;
      word-wrap: break-word;

      // overflow: scroll;
      .introductionHtml {
        max-height: 400px;
        width: 100%;
        word-wrap: break-word;
        overflow: hidden;
        // overflow-x: scroll;
      }
      .expand {
        max-height: none;
        overflow: none;
      }
    }
  }
  .el-breadcrumb {
    font-size: 1rem;
  }

  .top {
    display: flex;
    .intro {
      // margin-top: 0.5rem;
      //margin-left: 0.5rem;
      flex-grow: 1;
      span {
        font-size: 0.9rem;
        font-weight: 700;
      }
      .name {
        font-size: 1.2rem;
      }
      .brief {
        margin: 0.5rem 0;
        line-height: 1.2rem;
        color: #ff9900;
      }
      .mix {
        display: flex;
        .socialMedia {
          margin-left: 1rem;
          a {
            color: #000;
            margin: 0 0.3rem;
          }
        }
      }
    }
  }

  .video {
    iframe {
      width: 100%;
      height: 35vh;
    }
  }
  .profile {
  }
  .basicInfo {
    .info div {
      margin: 0.5rem 0;
    }
  }
  .h5 {
    font-size: 1rem;
    color: black;
  }
  :deep .el-tag {
    border: none;
    margin-bottom: 1rem;
  }
  :deep .el-avatar {
    box-shadow: 3px 3px 5px 3px $main_gray;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  :deep .el-collapse-item__header {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .teacher_introduce {
    width: 75vw;
    .teacher_info {
      width: 60%;
    }
    .video {
      iframe {
        height: 60vh;
      }
    }
  }
}
</style>
